<template>
  <div
    class="bitts-layout"
    :class="{
      [layoutClasses]: true,
      'bitts-layout--equal': variant === 'equal',
      'bitts-layout--even': variant === 'even',
    }"
  >
    <div
      v-for="(slot, idx) of Object.keys($slots)"
      :key="`content-slot__${idx}`"
      class="flex flex-col"
      :class="[getLayoutClasses(idx), pointbreakClass]"
    >
      <slot :name="slot" />
    </div>
  </div>
</template>

<script lang="ts">
import { usePointbreak } from '@crossbeam/pointbreak';

import { computed } from 'vue';

export type BittsLayoutVariant =
  | 'full'
  | 'fourths'
  | 'thirds'
  | 'half'
  | 'equal'
  | 'even';
export type BittsLayoutGap = 0 | 4 | 8 | 12 | 16 | 24 | 32 | 48;
export interface BittsLayoutProps {
  isPage?: boolean;
  variant?: BittsLayoutVariant;
  gap?: BittsLayoutGap;
  reverse?: boolean;
  layoutClasses?: string;
  /** Only applicable for equal variant. Controls the minimum width of the column. */
  minColWidth?: number;
}
</script>
<script setup lang="ts">
const props = withDefaults(defineProps<BittsLayoutProps>(), {
  isPage: false,
  variant: 'full',
  gap: 0,
  reverse: false,
  layoutClasses: '',
  minColWidth: 360,
});

const dynamicGap = computed(() => `${props.gap}px`);
const dynamicPadding = computed(() => {
  if (!props.isPage) return '0px';
  if (['xxl', 'xl'].includes(pointbreak.value ?? '')) return '40px';
  if (['lg', 'md', 'sm'].includes(pointbreak.value ?? '')) return '24px';
  return '16px';
});
const dynamicTopPadding = computed(() =>
  props.isPage ? '24px' : dynamicPadding.value,
);
const dynamicColMinWidth = computed(() =>
  props.minColWidth ? `${props.minColWidth}px` : '0px',
);

/* This function constructs the classes needed to arrange the elements in the grid. */
function getLayoutClasses(idx: number) {
  const classes = [];

  let base = `bitts-layout-${props.variant}`;
  if (props.variant !== 'full') base += `__${idx + 1}`;
  classes.push(base);

  /* Used for reversing split (1/3 and 2/3 versus 2/3 and 1/3, etc) */
  if (props.reverse) classes.push('reverse');

  return classes;
}

/* We're using pointbreak to determine when to stack our columns,
when BittsLayout is used at the page level */
const pointbreak = usePointbreak();
const pointbreakClass = computed(() => {
  if (!props.isPage) return '';
  if (props.variant === 'half' && ['xs', 'sm'].includes(pointbreak.value ?? ''))
    return 'stacked';
  if (
    ['fourths', 'thirds'].includes(props.variant) &&
    ['xs', 'sm', 'md'].includes(pointbreak.value || '')
  )
    return 'stacked';
  return '';
});

const evenColCount = computed(() => {
  switch (pointbreak.value) {
    case 'xs':
      return 1;
    case 'sm':
      return 1;
    case 'md':
      return 2;
    case 'lg':
      return 3;
    case 'xl':
      return 3;
    default:
      return 4;
  }
});

defineExpose({ pointbreakClass }); /* For testing */
</script>

<style lang="pcss" scoped>
/* Core grid layout styles */
.bitts-layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
  gap: v-bind(dynamicGap);
  row-gap: v-bind(dynamicGap);
  padding: v-bind(dynamicPadding);
  padding-top: v-bind(dynamicTopPadding);
  @apply h-full flex-1;
}

/* The equal variant will fit as many columns onto the page using the minimum
width provided. When the available space is not evenly divisible, it'll leave
empty space to the side. Good for a list of elements where you don't care about
having them stretch to fill the full remaining space
See: https://developer.mozilla.org/en-US/docs/Web/CSS/minmax

*/
.bitts-layout.bitts-layout--equal {
  grid-template-columns: repeat(
    auto-fit,
    minmax(v-bind(dynamicColMinWidth), 1fr)
  );
}

/* The event variant will set up 4, 3, 2, or 1 columns on the page. This is good
for pages where you'd like to show a grid, but you want the elements to be placed
evenly and take up all available space. */

.bitts-layout.bitts-layout--even {
  grid-template-columns: repeat(v-bind(evenColCount), 1fr);
}

/* Layout slots */
.bitts-layout-full {
  grid-column-start: 1;
  grid-column-end: 13;
}

.bitts-layout-half__1 {
  grid-column-start: 1;
  grid-column-end: 7;
}

.bitts-layout-half__2 {
  grid-column-start: 7;
  grid-column-end: 13;
}

.bitts-layout-thirds__1 {
  grid-column: 1 / span 4;
  &.reverse {
    grid-column: 1 / span 8;
  }
}

.bitts-layout-thirds__2 {
  grid-column: 5 / -1;
  &.reverse {
    grid-column: 9 / -1;
  }
}

.bitts-layout-fourths__1 {
  grid-column: 1 / span 3;
  &.reverse {
    grid-column: 1 / span 9;
  }
}

.bitts-layout-fourths__2 {
  grid-column: 4 / -1;
  &.reverse {
    grid-column: 10 / -1;
  }
}

.stacked {
  grid-column-start: 1 !important;
  grid-column-end: 13 !important;
}
</style>
